<template>
  <div class="w-full rounded-lg p-1 sm:rounded-3xl bg-white-2 sm:p-4 text-black-1 font-poppins flex gap-2 card-shadow">
    <div class="relative w-4/12">
      <img class="w-full h-full rounded-lg mobileL:rounded-3xl" :src="product.image" :alt="product.name">
    </div>
    <div class="card-body w-8/12 flex flex-col justify-between">
      <div class="font-bold h-auto my-0.5 mobileL:my-1.5">
        <h2 class="card-title text-xs mobileL:text-tiny 2xl:text-lg">{{ product.name }}</h2>
      </div>

      <div class="description-container hide-scroll text-xxs mobileL:text-xs text-gray-500">
        <p class="font-normal text-xs sm:text-tiny">{{ product.desc }}</p>
      </div>

      <div class="flex justify-between items-center font-bold mt-5 mobileL:mt-4">
        <h2 class="card-title text-xs mobileL:text-lg">P{{ new Intl.NumberFormat('en-PH').format(product.srp) }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ['product']
}
</script>

<style scoped>
.hide-scroll::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.description-container {
  line-height: 1.20em;
  min-height: calc(1.20em * 5);
}

.card-shadow {
  box-shadow: 0px 8px 4px 0px #BC6DAB;
}
</style>